import { computed, ref, watch } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import route from '@/router'

export default function useFormatosList() {
  const toast = useToast()

  const refListTable = ref(null)

  const tableColumns = [
    {
      key: 'descricao',
      sortable: true,
      label: 'Descrição',
    },
    {
      key: 'localizacao',
      label: 'localização',
    },
    {
      key: 'disciplinas',
      label: 'disciplinas',
    },
    {
      key: 'tarefas_count',
      label: 'Realizado',
      class: 'text-center',
      sortable: false,
    },

    { key: 'Ações' },
  ]
  const perPage = ref(10)
  const totalItens = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('descricao')
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    currentPage.value - 1

    const localItemsCount = refListTable.value
      ? refListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItens.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const deleteChecklist = id => {
    store.dispatch('app-projetos/deleteChecklist', id)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Checklist excluído com sucesso',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        refetchData()
      })
  }

  const fetchDados = (ctx, callback) => {
    store
      .dispatch('app-projetos/fetchChecklists', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        projeto_id: route.history.current.params.id,
      })
      .then(response => {
        callback(response.data)
        totalItens.value = response.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao listar os checklists',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const update = item => {
    store.dispatch('app-projetos/update', item)
      .then(() => {
        refetchData()

        toast({
          component: ToastificationContent,
          props: {
            title: 'Projeto editado com sucesso',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
  }
  const deleteItem = id => {
    store.dispatch('app-projetos/deleteItem', id)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Projeto excluído com sucesso',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        refetchData()
      })
  }

  return {
    fetchDados,
    update,
    deleteChecklist,
    tableColumns,
    perPage,
    currentPage,
    totalItens,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    refetchData,
  }
}
