<template>
  <div>

    <form-create
        :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
        :lista-empresas="listaEmpresas"
        titulo="Novo Checklist"
        @refetch-data="refetchData"
    />

    <b-card
        no-body
        class="mb-0"
    >
      <h5 class=" pt-2 pl-2 pb-0 mb-0 font-weigth-normal">Checklists do Projeto: {{
          getNomeProjeto
        }}</h5>

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
              cols="12"
              md="4"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibição</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />

          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Pesquisar..."
              />
              <b-button
                  :to="linkLocalizacoes"
                  class="mr-1"
                  variant="primary"
              >
                <span class="text-nowrap">
                  Ver Localizações
                </span>
              </b-button>
              <b-button
                  variant="success"
                  :to="novoChecklist"
              >
                <span class="text-nowrap">
                  Adicionar Checklist
                </span>
              </b-button>

            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="fetchDados"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Nenhum resultado encontrado"
          :sort-desc.sync="isSortDirDesc"
          :busy="isLoad"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"/>
            <strong> Carregando...</strong>
          </div>
        </template>
        <template #cell(descricao)="data">
          <div><small>{{ data.item.descricao }}</small></div>
          <div class="font-size-8 text-primary"><strong>Atualizado em: </strong>{{
              data.item.updated_at | formatDataHora
            }}
          </div>
        </template>
        <template #cell(disciplinas)="data">
          <div class="displinasProjetos">
            <tag-color
                v-for="(item, index) in data.item.disciplinas"
                :key="index"
                v-b-tooltip.hover.top.v-primary
                class="mb-1 cursor"
                :texto="item.abreviacao"
                :title="item.nome"
                :bg-color="item.bg_color"
            />

          </div>
        </template>

        <template #cell(localizacao)="data">
          <div><small>{{ data.item.localizacao.descricao }}</small></div>
        </template>
        <template #cell(tarefas_count)="data">
          <div>{{ data.item.tarefas_concluidas_count }} de {{ data.item.tarefas_count }}</div>
        </template>

        <!-- Column: Actions -->
        <template #cell(ações)="data">
          <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <!-- <b-dropdown-item @click="editarItem(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item> -->

            <b-dropdown-item @click="modalExcluir(data.item)">
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} dos {{ dataMeta.of }} resultados
              encontrados</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="currentPage"
                :total-rows="totalItens"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <modalConfirmacao
          id="modal-confirmacao"
          titulo="Excluir Item"
          :item-id="dadosItem.uuid"
          :item-nome="dadosItem.descricao"
          @itemConfirmado="excluirItem"
          @click="modalExcluir(data.item)"
      />
    </b-card>

    <b-modal
        id="modal-editar"
        v-model="modalShow"
        :title="`Editar ${dadosItem.descricao}`"
    >
      <validation-provider
          #default="validationContext"
          name="Nome do Projeto"
          rules="required"
      >
        <b-form-group
            label="Nome do Projeto"
            label-for="nome-projeto"
        >
          <b-form-input
              id="nome-projeto"
              v-model="dadosItem.descricao"
              name="projeto"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="Informe o nome do projeto"
          />

          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>

        </b-form-group>
      </validation-provider>
      <validation-provider
          #default="validationContext"
          name="Data Entrega"
          rules="required"
      >
        <b-form-group
            label="Data Entrega"
            label-for="data_entrega"
        >
          <b-form-input
              id="data_entrega"
              v-model="dadosItem.data_entrega"
              type="date"
              :state="getValidationState(validationContext)"
          />

          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <validation-provider
          #default="validationContext"
          name="Empresa"
          rules="required"
      >
        <b-form-group
            label="Empresa"
            label-for="empresa"
            :state="getValidationState(validationContext)"
        >
          <v-select
              v-model="dadosItem.empresa"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="listaEmpresas"
              label="nome"
              :reduce="val => val.uuid"
              :clearable="false"
              input-id="user-empresa"
              placeholder="Escolha uma empresa"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-form-group
          label="Escolha o cliente"
          label-for="cliente"
      >
        <select-com-pesquisa
            url="/clientes"
            placeholder="Escolha o cliente"
            :multiple="false"
            :itens-para-edicao="[dadosItem.cliente]"
            @selecionados="tratarEventoClienteSelecionados"
        />

      </b-form-group>
      <b-form-group
          label="Escolha as disciplinas"
          label-for="disciplinas"
      >
        <select-com-pesquisa
            url="/disciplinas"
            placeholder="Escolha as disciplinas"
            :multiple="true"
            :itens-para-edicao="dadosItem.disciplinas"
            @selecionados="tratarEventoDisciplinasSelecionadas"
        />

      </b-form-group>
      <template #modal-footer>
        <div class="w-100">

          <b-button
              variant="primary"
              size="md"
              class="float-left"
              @click="modalShow = false"
          >
            Fechar
          </b-button>
          <b-button
              variant="success"
              size="md"
              class="float-right"
              @click="salvarItemEditado(dadosItem)"
          >
            Salvar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  VBTooltip,
} from 'bootstrap-vue'

import { onUnmounted, ref } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import modalConfirmacao from '@/views/components/componentesPensou/ModalConfirmacao.vue'
import projetosStoreModule from '@/views/apps/projetos/projetosStoreModule.js'
import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'
import store from '@/store'
import useChecklistList from './useChecklistList'
import vSelect from 'vue-select'
import FormCreate from './FormCreate.vue'
import TagColor from '@/views/components/componentesPensou/TagColor.vue'

export default {
  components: {
    TagColor,
    FormCreate,
    modalConfirmacao,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    vSelect,
    SelectComPesquisa,
    ValidationProvider,

  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  setup() {
    const blankUserData = {
      descricao: '',
    }

    const PROJETOS_APP_STORE_MODULE_NAME = 'app-projetos'

    // Register module
    if (!store.hasModule(PROJETOS_APP_STORE_MODULE_NAME)) store.registerModule(PROJETOS_APP_STORE_MODULE_NAME, projetosStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROJETOS_APP_STORE_MODULE_NAME)) store.unregisterModule(PROJETOS_APP_STORE_MODULE_NAME)
    })

    const itemData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const isAddNewSidebarActive = ref(false)
    const listaEmpresas = ref([])

    store.dispatch('app-projetos/listaEmpresas')
        .then(response => {
          listaEmpresas.value = response.data
        })

    const {
      fetchDados,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      deleteChecklist,
      update,

    } = useChecklistList()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemData)

    return {
      isAddNewSidebarActive,
      deleteChecklist,
      fetchDados,
      update,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      refFormObserver,
      getValidationState,
      resetForm,
      itemData,
      listaEmpresas,
    }
  },
  filters: {

    formatDataHora(value) {
      const date = new Date(value)
      const options = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',

      }
      return date.toLocaleDateString('pt-BR', options)
    },
  },
  data() {
    return {
      dadosItem: {
        uuid: '',
        descricao: '',
        data_entrega: '',
        cliente: '',
        empresa: '',
        disciplinas: null,
      },
      novoChecklist: '',
      localizacoesProjeto: '',
      modalShow: false,

    }
  },
  computed: {
    isLoad() {
      return this.$store.state['app-projetos'].load
    },
    items() {
      return this.fetchDados
    },
    getNomeProjeto() {
      return this.$store.state['app-projetos'].projeto.descricao
    },
    linkLocalizacoes() {
      return this.localizacoesProjeto
    },

  },

  created() {
    this.novoChecklist = `${this.$route.path}/novo`
    this.criarLinkLocalizacoes()
  },
  methods: {
    criarLinkLocalizacoes() {
      this.localizacoesProjeto = {
        name: 'projeto-localizacoes',
        params: { id: this.$route.params.id },
      }
    },
    editarItem(item) {
      this.modalShow = true
      this.dadosItem = {
        ...item,
        data_entrega: this.formatarDataEdicao(item.data_entrega),
        empresa: item.empresa.uuid,

      }
    },

    excluirItem(dados) {
      this.deleteChecklist(dados)
    },

    modalExcluir(item) {
      this.dadosItem.descricao = item.descricao
      this.dadosItem.uuid = item.uuid
      this.$root.$emit('bv::show::modal', 'modal-confirmacao')
    },
    salvarItemEditado(item) {
      this.$root.$emit('bv::hide::modal', 'modal-editar')
      this.update(item)
    },
    tratarEventoClienteSelecionados(item) {
      this.itemData.cliente = item.id
    },
    tratarEventoDisciplinasSelecionadas(itens) {
      this.itemData.disciplinas = itens
    },
    formatarDataEdicao(dateString) {
      const dateParts = dateString.split('T')[0].split('-')
      const year = parseInt(dateParts[0])
      const month = parseInt(dateParts[1])
      const day = parseInt(dateParts[2])
      return `${year}-${month.toString()
          .padStart(2, '0')}-${day.toString()
          .padStart(2, '0')}`
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.per-page-selector {
  width: 90px;
}

.displinasProjetos {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 160px;

}
</style>
